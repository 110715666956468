<!-- 注册选择 -->
<template>
    <div class="RegisterOpt">
        <el-row>
            <el-col :span="24">
                <div class="top">
                    考级报名系统
                </div>
            </el-col>
                <div class="content">
                    <div class="content_c">
                        <div class="jigouReg">
                            <div class="Reg">新考生注册</div>
                            <div class="have" @click="exist">已有账号，马上去登录</div>
                        </div> 
                        <!-- 注册按钮 -->
                        <div class="regBtn">
                            <div class="">
                                <button type="button" class="btn-1" @click="mainland">中国大陆 二代身份证考生注册</button>
                            </div>
                            <div class="">
                                <button type="button" class="btn-2" @click="HMTreg">港澳台境外考生注册</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </el-col>
        </el-row>    
    </div>
</template>

<script>
    export default{
        data() {
            return{
                
            }
        },
        created() {
            
        },
        methods:{
            exist(){//已有账号
                this.$router.push({
                    path: "/",
                })
            },
            mainland(){//大陆身份证注册
                this.$router.push({
                    path: "/register",
                })
            },
            HMTreg(){//港澳台注册
                this.$router.push({
                    path: "/registerHMT",
                })
            }
        }
    }
</script>

<style scoped="scoped">
    @media screen and (min-width:320px) {
        .top {
            font-size: 13px !important;
            height: 30px !important;
            line-height: 30px !important;
        }
        .submit{
            width: 280px !important;
        }
        .Reg{
            font-size: 15px !important;
        }
        .have{
            font-size: 12px !important;
        }
    }
    
    @media screen and (min-width:375px) {
        .top {
            font-size: 15px !important;
            height: 40px !important;
            line-height: 40px !important;
        }
    
        .content_c {
            width: 300px !important;
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16) !important;
        }
        .btn-1,.btn-2{
            font-size:16px !important;
            width: 260px !important;
            height: 35px !important;
        }
        .Reg{
            font-size: 18px !important;
        }
        .have{
            font-size: 13px !important;
        }
    }
    
    @media screen and (min-width:768px) {
        .top {
            font-size: 18px !important;
            height: 50px !important;
            line-height: 50px !important;
        }
        .content_c {
            width: 500px !important;
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16) !important;
        }
        .btn-1,.btn-2{
            font-size:18px !important;
            width: 300px !important;
            height: 40px !important;
        }
        .Reg{
            font-size: 20px !important;
        }
        .have{
            font-size: 15px !important;
        }
    }
    
    @media screen and (min-width:1024px) {
        .top {
            font-size: 28px !important;
            height: 70px !important;
            line-height: 70px !important;
        }
    
        .content_c {
            width: 700px !important;
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16) !important;
        }
        .btn-1,.btn-2{
            font-size:20px ;
            width: 383px !important;
        }
    }
    
    .RegisterOpt{
        width: 100vw;
        height: 100vh;
    }
    .top {
        width: 100%;
        height: 70px;
        text-align: center;
        line-height: 70px;
        font-size: 30px;
        background: #29726C;
        color: #fff;
    }
    .content {
        width: 100vw;
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .content_c {
        width: 520px;
        height: 444px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
    }
    .jigouReg {
        width: 100%;
        height: 40px;
        line-height: 60px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    
    .Reg {
        margin-left: 20px;
        color: #212121;
        font-size: 20px;
        font-weight: 500;
    }
    
    .have {
        margin-right: 20px;
        color: #707070;
        font-size: 14px;
        cursor: pointer;
    }
    .regBtn{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
    }
    .btn-1{
        display: inline-block;
        width:383px ;
        height: 55px;
        font-size: 20px;
        background: #1AAD19;
        color: #fff;
        border-radius: 5px;
        border: none;
        outline: none;
        cursor: pointer;
    }
    .btn-2{
        display: inline-block;
        width:383px ;
        height: 55px;
        font-size: 20px;
        margin-top: 20px;
        background: #E64340 ;
        color: #fff;
        border-radius: 5px;
        border: none;
        outline: none;
        cursor: pointer;
    }
</style>
